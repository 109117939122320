import { useInsertionEffect } from 'react';

import { ConditionalWrapper } from '@/hoc';
import { Container, Link, NextLink, Text } from '@/atoms';
import {
  Button,
  CardAccessory,
  Grid,
  Pill,
  WithPaperBorder,
} from '@/molecules';
import { Positions } from '@/molecules/with-paper-border/types';

import { removeBaseURL } from '@/lib/utils';

import type { TypeImageWordpress } from '@/components/types';
import type { ButtonProps } from '@/molecules/button';
import type { TypeGridProps } from '@/molecules/grid';
import type { ElementType, ReactNode } from 'react';

type ContentAfterAccessories =
  | {
      textStyle: 'default';
    }
  | {
      textStyle: 'custom';
      textColor: string;
      textDesktopSize: number;
      textMobileSize: number;
      textTransform: 'default' | 'allUpercase' | 'allLowercase' | 'normal';
    };

type TypeAccesorios = {
  className?: string;
  id: string;
  bloque: {
    papelRasgado: {
      border?: 'inside' | 'outside';
      borderColor?: string;
      borderHeight?: number;
      zona: 'none' | 'top' | 'bottom' | 'top bottom';
    };
    backgroundColorString?: string;
    backgroundColorStringCard?: string;
    backgroundColorCardOutline?: string;
    textoNegativo?: boolean;
    message?: string;
    titulo?: string;
    headingType: ElementType;
    headingSize:
      | 'u-headline--h1'
      | 'u-headline--h2'
      | 'u-headline--h3'
      | 'u-headline--h4';
    imageMaxSize?: number;
    titleAlign: 'text-left' | 'text-center' | 'text-right';
    link?: {
      label: string;
      link: {
        url: string;
      };
      size: 'body' | 'small' | 'large';
      style: 'primary' | 'secondary' | 'alternative' | 'current-color';
    };
    itemsDesktop: TypeGridProps['lg'];
    itemsTablet: TypeGridProps['md'];
    itemsMobile:
      | TypeGridProps['sm']
      | TypeGridProps['xs']
      | TypeGridProps['xxs'];
    cardStyle: 'home' | 'marca';
    categorias?: Array<{
      header?: string;
      imagen?: TypeImageWordpress;
      link?: string;
      titulo?: string;
      description?: string;
      extraButton?: ReactNode | ReactNode[] | (() => ReactNode | ReactNode[]);
    }>;
    listOfCategories: {
      title?: string;
      categories?: Array<{
        name: string;
        uri: string;
      }>;
    };
    contentAfterAccessories?: {
      text?: string;
      headingType: ElementType;

      label?: string;
      link?: {
        url: string;
      };
      negativo?: boolean;
      size?: ButtonProps['size'];
      style?: ButtonProps['variant'];
    } & ContentAfterAccessories;
  };
};

const isHomeType = (type: string) => type === 'home';

const AccesoriosFlexible = ({ bloque, id, className = '' }: TypeAccesorios) => {
  useInsertionEffect(() => {
    if (
      !bloque.contentAfterAccessories?.text ||
      bloque.contentAfterAccessories.textStyle !== 'custom'
    ) {
      return;
    }

    const { headingType, textDesktopSize, textMobileSize, textTransform } =
      bloque.contentAfterAccessories;
    const FONT_TEXT_TRANSFORM = `
      font-family: var(--font-family-secondary); 
      letter-spacing: 1px;
    `;

    const styleElement = document.createElement('style');
    styleElement.textContent += `
        .accesorios-${id}-contentAfterAccessories-text {
            font-size: ${textMobileSize || 18}px;
            text-align: center;
            ${textTransform === 'allUpercase' ? 'text-transform: uppercase;' : ''}
            ${
              textTransform === 'allLowercase'
                ? `
                    text-transform: lowercase;
                    ${headingType !== 'p' ? FONT_TEXT_TRANSFORM : ''}
                  `
                : ''
            }
            ${
              textTransform === 'normal'
                ? `
                    text-transform: none;
                    ${headingType !== 'p' ? FONT_TEXT_TRANSFORM : ''}
                  `
                : ''
            }
        }

        @media (min-width: 768px) {
          .accesorios-${id}-contentAfterAccessories-text {
              font-size: ${textDesktopSize || 24}px;
          } 
        }
    `;

    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <ConditionalWrapper
      condition={
        bloque.papelRasgado.zona === Positions.top ||
        bloque.papelRasgado.zona === Positions.topBottom ||
        bloque.papelRasgado.zona === Positions.bottom
      }
      wrapper={(children) => (
        <WithPaperBorder
          id={id}
          top={
            bloque.papelRasgado.zona === Positions.top ||
            bloque.papelRasgado.zona === Positions.topBottom
          }
          bottom={
            bloque.papelRasgado.zona === Positions.bottom ||
            bloque.papelRasgado.zona === Positions.topBottom
          }
          border={bloque.papelRasgado.border}
          borderHeight={bloque.papelRasgado.borderHeight}
          borderColor={bloque.papelRasgado.borderColor}
        >
          {children}
        </WithPaperBorder>
      )}
    >
      <Container
        id={id}
        wrapper
        as="section"
        className={`w-full overflow-hidden ${className}`}
        classNameWrapper="gap-y-4 md:gap-y-8"
        {...(bloque.backgroundColorString
          ? {
              style: {
                backgroundColor: bloque.backgroundColorString,
                paddingBlock: '25px',
              },
            }
          : {})}
      >
        <div className="flex flex-col gap-4 lg:gap-2">
          {bloque.message && (
            <p className="u-body u-body--xs text-right lg:u-body--s">
              {bloque.message}
            </p>
          )}
          <div
            className={`${
              bloque.textoNegativo ? 'text-bg-secondary' : ''
            }flex flex-row items-baseline justify-between`}
          >
            {bloque.titulo && (
              <Text
                as={bloque.headingType}
                className={`u-headline ${
                  bloque?.headingSize ?? 'u-headline--h2'
                } grow ${bloque.titleAlign}`}
              >
                {bloque.titulo}
              </Text>
            )}

            {bloque.link?.link && (
              <NextLink
                href={removeBaseURL(bloque.link.link.url) ?? '/'}
                passHref
              >
                <Link size={bloque.link.size} type={bloque.link.style}>
                  {bloque.link.label}
                </Link>
              </NextLink>
            )}
          </div>
        </div>

        <Grid
          xxs={bloque.itemsMobile}
          xs={bloque.itemsMobile}
          sm={bloque.itemsMobile}
          md={bloque.itemsTablet}
          lg={bloque.itemsDesktop}
          className={
            isHomeType(bloque.cardStyle) ? '!gap-0' : '!gap-x-3 !gap-y-4'
          }
        >
          {bloque.categorias?.map((categoria, indexCategory) => (
            <Grid.Item key={indexCategory} className="!outline-0">
              <CardAccessory
                {...(categoria?.link && {
                  imageLink: removeBaseURL(categoria?.link ?? '/'),
                })}
                type={bloque.cardStyle}
                header={categoria.header}
                image={categoria.imagen}
                title={categoria.titulo}
                description={categoria.description}
                imageMaxSize={bloque.imageMaxSize}
                {...(bloque?.backgroundColorStringCard
                  ? {
                      style: {
                        backgroundColor: bloque?.backgroundColorStringCard,
                        ...(!isHomeType(bloque.cardStyle) &&
                          bloque.backgroundColorCardOutline && {
                            outlineStyle: 'solid',
                            outlineWidth: '1px',
                            outlineColor: bloque.backgroundColorCardOutline,
                          }),
                      },
                    }
                  : {})}
                extraButton={categoria.extraButton}
              />
            </Grid.Item>
          ))}
        </Grid>

        {bloque?.listOfCategories?.title && (
          <div className="mt-6 flex flex-row items-center gap-10 overflow-x-scroll">
            {bloque.listOfCategories.title && (
              <span className="u-headline u-headline--h4 flex-shrink-0">
                {bloque.listOfCategories.title}
              </span>
            )}

            <div className="flex flex-shrink-0 flex-row gap-2">
              {bloque.listOfCategories?.categories
                ?.filter(Boolean)
                ?.map((category: any, index: number) => (
                  <NextLink href={category.uri} key={index} passHref>
                    <Pill as="a" theme="tertiary">
                      {category.name}
                    </Pill>
                  </NextLink>
                ))}
            </div>
          </div>
        )}

        {(bloque.contentAfterAccessories?.text ||
          (bloque.contentAfterAccessories?.label &&
            bloque.contentAfterAccessories?.link?.url)) && (
          <div className="flex flex-wrap items-center justify-center gap-x-9 gap-y-2">
            {bloque.contentAfterAccessories?.text && (
              <Text
                as={bloque.contentAfterAccessories.headingType}
                style={{
                  color:
                    bloque.contentAfterAccessories.textStyle === 'default'
                      ? '#262626'
                      : bloque.contentAfterAccessories.textColor || '#262626',
                }}
                className={` ${
                  bloque.contentAfterAccessories.textStyle === 'default'
                    ? 'u-headline--h3 text-center'
                    : `accesorios-${id}-contentAfterAccessories-text`
                } ${bloque.contentAfterAccessories.headingType === 'p' ? 'u-body' : 'u-headline'}`}
              >
                {bloque.contentAfterAccessories.text}
              </Text>
            )}

            {bloque.contentAfterAccessories.link?.url &&
              bloque.contentAfterAccessories.label && (
                <NextLink
                  href={
                    removeBaseURL(bloque.contentAfterAccessories.link.url) ??
                    '/'
                  }
                  passHref
                >
                  <Button
                    as="a"
                    variant={bloque.contentAfterAccessories.style}
                    size={bloque.contentAfterAccessories.size}
                    negative={bloque.contentAfterAccessories.negativo}
                  >
                    {bloque.contentAfterAccessories.label}
                  </Button>
                </NextLink>
              )}
          </div>
        )}
      </Container>
    </ConditionalWrapper>
  );
};

export default AccesoriosFlexible;

AccesoriosFlexible.displayName = 'FlexibleAccesorios';
