import dynamic from 'next/dynamic';

import { Container } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeWithPaperBorderProps } from './types';

const PaperTextureBottom = dynamic(
  () =>
    import('@/icons/PaperTextureBottom').then((mod) => mod.PaperTextureBottom),
  { ssr: false },
);

const PaperTextureTop = dynamic(
  () => import('@/icons/PaperTextureTop').then((mod) => mod.PaperTextureTop),
  { ssr: false },
);

/**
 * WithPaperBorder
 */
export const WithPaperBorder: FunctionComponent<TypeWithPaperBorderProps> = ({
  className = '',
  top = false,
  bottom = false,
  border = 'inside',
  borderHeight = 12,
  borderColor = 'var(--tw-color-bg-primary)',
  id,
  children,
}: TypeWithPaperBorderProps) => (
  <div id={id} className={`w-full ${className}`}>
    <div className="relative flex h-full w-full justify-center overflow-hidden">
      {top && border === 'outside' && (
        <PaperTextureTop
          className="absolute -top-px left-0 right-0 z-10 w-full content-['']"
          height={borderHeight}
          color={borderColor}
        />
      )}
      {top && border === 'inside' && (
        <PaperTextureBottom
          className="absolute -top-px left-0 right-0 z-10 w-full content-['']"
          height={borderHeight}
          color={borderColor}
        />
      )}
      <Container
        as="div"
        style={{
          padding: 0,
          ...((top || bottom) && {
            paddingBlock: border === 'outside' ? borderHeight - 2 : 0,
          }),
        }}
        className="w-full"
      >
        {children}
      </Container>

      {bottom && border === 'outside' && (
        <PaperTextureBottom
          className="absolute -bottom-px left-0 right-0 z-10 w-full content-['']"
          height={borderHeight}
          color={borderColor}
        />
      )}
      {bottom && border === 'inside' && (
        <PaperTextureTop
          className="absolute -bottom-px left-0 right-0 z-10 w-full content-['']"
          height={borderHeight}
          color={borderColor}
        />
      )}
    </div>
  </div>
);
