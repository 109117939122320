import React, { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import type { PolymorphicRef } from '@/types';
import type { ElementType } from 'react';
import type { TypePillProps } from './types';

/**
 * Pill
 */
const PillBase = <T extends ElementType>(
  {
    children,
    className,
    as,
    theme,
    textColor = 'white',
    ...props
  }: TypePillProps<T>,
  ref: PolymorphicRef<T>,
) => {
  const Component = as || 'span';

  return (
    <Component
      className={oneLine`u-actions u-actions--chips rounded-full px-6 py-2 text-center ${
        theme === 'primary'
          ? 'bg-primary-100 aria-disabled:opacity-50 hover:bg-primary-200 hover:text-typo-primary disabled:opacity-50'
          : ' '
      } ${
        theme === 'secondary'
          ? 'bg-primary-200 aria-disabled:opacity-50 hover:bg-primary-900 hover:text-typo-alternative disabled:opacity-50'
          : ' '
      } ${
        theme === 'tertiary'
          ? 'border border-primary-900 aria-disabled:border-primary-200 aria-disabled:text-primary-200 hover:border-primary-600 hover:text-primary-600 disabled:border-primary-200 disabled:text-primary-200'
          : ' '
      } ${
        theme === 'brand'
          ? 'bg-action aria-disabled:opacity-50 hover:bg-gradient-to-t hover:from-[#0000004D] hover:to-[#0000004D] disabled:opacity-50'
          : ''
      } ${
        theme === 'custom'
          ? 'aria-disabled:opacity-50 hover:bg-gradient-to-t hover:from-[#0000004D] hover:to-[#0000004D] disabled:opacity-50'
          : ''
      } ${textColor === 'white' ? 'text-white' : ''} ${textColor === 'black' ? 'text-typo-primary' : ''} ${className} `}
      ref={ref}
      {...props}
    >
      {children}
    </Component>
  );
};

PillBase.displayName = 'Pill';

export const Pill = forwardRef(PillBase);
